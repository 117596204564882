import { defineStore } from 'pinia'

export const useClientStoreChatStore = defineStore('clientStoreChatStore', {
  state: () => ({
    selected: null,
    unreads: [],
  }),
  actions: {
    async fetches(params: any) {
      return await useApi<any>(createUrl('/client_store_chats', {
        query: params,
      }))
    },

    async fetch(id: number) {
      return await $api<any>(`/client_store_chats/${id}`)
    },

    async setAdminReaded(id: number) {
      return await $api(`/client_store_chats/admin/readed/${id}`, {
        method: 'GET',
      })
    },

    async setClientReaded(id: number) {
      return await $api(`/client_store_chats/client/readed/${id}`, {
        method: 'GET',
      })
    },

    async store(data: any, url: string) {
      return await $api(`${url}`, {
        method: 'POST',
        body: data,
      })
    },

    async update(data: any) {
      return await $api(`/client_store_chats/${data.id}`, {
        method: 'PUT',
        body: data,
      })
    },

    async delete(id: number) {
      return await $api(`/client_store_chats/${id}`, {
        method: 'DELETE',
      })
    },

    async download(data: any) {
      return await $api('/client_store_chats/download', {
        params: data,
      })
    },
  },
})
